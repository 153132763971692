/**
 * Updated june 28, 2023 to match backend - https://github.com/KeyLiving/services/blob/master/services/api-service/src/constants/invoice.ts
 */

export enum RESIDENCY_PAYMENT {
    CARRYING_COST = 'CARRYING_COST',
    CONDO_FEES = 'CONDO_FEES',
    CO_FINANCING_INTEREST = 'CO_FINANCING_INTEREST',
    HAAS_FEE = 'HAAS_FEE',
    OWNERSHIP_SAVINGS = 'OWNERSHIP_SAVINGS',
    RENT = 'RENT',
    RENT_EQUIVALENT = 'RENT_EQUIVALENT',
    SUITE_EXPENSES = 'SUITE_EXPENSES',
}

export enum RECURRING_SERVICES {
    HYDRO = 'HYDRO',
    LOCKER = 'LOCKER',
    PARKING = 'PARKING',
    REPAIR_AND_MAINTENANCE = 'REPAIR_AND_MAINTENANCE',
    RESIDENT_INSURANCE = 'RESIDENT_INSURANCE',
    WATER = 'WATER',
}

export enum EQUITY_TRANSACTIONS {
    AUTO_EQUITY_PURCHASE = 'AUTO_EQUITY_PURCHASE',
    BETA_EQUITY_BOOST_CREDIT = 'BETA_EQUITY_BOOST_CREDIT',
    EQUITY_ADMIN_FEE = 'EQUITY_ADMIN_FEE',
    EQUITY_BOOST = 'EQUITY_BOOST',
    EQUITY_PURCHASE = 'EQUITY_PURCHASE',
    INITIAL_INVESTMENT = 'INITIAL_INVESTMENT',
    OWNERSHIP_SAVINGS_REINVESTMENT = 'OWNERSHIP_SAVINGS_REINVESTMENT',
}

export enum ADDITIONAL_CHARGES_OR_CREDITS {
    ADJUSTMENT_GENERAL = 'ADJUSTMENT_GENERAL',
    ADJUSTMENT_RENT = 'ADJUSTMENT_RENT',
    ADJUSTMENT_SERVICES = 'ADJUSTMENT_SERVICES',
    FAILED_PAYMENT_FEE_1 = 'FAILED_PAYMENT_FEE_1',
    FAILED_PAYMENT_FEE_2 = 'FAILED_PAYMENT_FEE_2',
    FAILED_PAYMENT_FEE_3 = 'FAILED_PAYMENT_FEE_3',
    MOVING_SERVICES = 'MOVING_SERVICES',
    PREVIOUS_BALANCE = 'PREVIOUS_BALANCE',
    RESIDENT_ONBOARDING_FEE = 'RESIDENT_ONBOARDING_FEE',
    WEAR_AND_TEAR_CHARGE = 'WEAR_AND_TEAR_CHARGE',
}

export const INVOICE_SECTIONS = [
    { header: 'Residency Payment', items: RESIDENCY_PAYMENT },
    { header: 'Recurring Services', items: RECURRING_SERVICES },
    { header: 'Equity Transactions', items: EQUITY_TRANSACTIONS },
    { header: 'Additional Charges Or Credits', items: ADDITIONAL_CHARGES_OR_CREDITS },
];
